import React, {useState, useEffect} from 'react';
import './connect.css';
import LogoConnect from '../../image/logoConnect.png';
import { Link } from 'react-router-dom';
import Arrow from '../../image/arrow.png';
import { ToastContainer, toast } from 'react-toastify';
import {writeShop, readShop } from '../Firebase/Firebase';
import 'react-toastify/dist/ReactToastify.css';



const ConnectStore = ({user, plan}) => {

  const [connectedStores, setConnectedStores] = useState([]);

  useEffect(() => {
    readShop(user.uid)
      .then(response => {
        setConnectedStores(response);
      })
      .catch(error => console.error('Error fetching connected stores:', error));
  }, [user.uid]);


const handleSubmit = (e) => {
e.preventDefault();
const shopName = e.target.shopName.value;
const apiKey = e.target.apiKey.value;
const password = e.target.password.value;
console.log(shopName)
const isShopNamePresent = connectedStores.some(store => store.shopName.toLowerCase() === shopName.toLowerCase());
if (plan === '3seconds Starter' && connectedStores.length >= 1 && !isShopNamePresent) {
  toast.error('You can connect only one store with the starter plan.');
} else if (plan === '3seconds Pro' && connectedStores.length >= 3 && !isShopNamePresent) {
  toast.error('You can connect up to three stores with the pro plan.');
} else if (plan === '3seconds God' && connectedStores.length >= 5 && !isShopNamePresent) {
  toast.error('You can connect up to five stores with the god plan.');
} else {
  const date = {
    shopName: shopName,
    apiKey: apiKey,
    password: password
 };
 fetch('https://threeseconds.onrender.com/connect-shopify', {
   method: 'POST',
   headers: {
       'Content-Type': 'application/json',
   },
  credentials: 'include',
   body: JSON.stringify(date),
 })
   .then(response => response.json())
   .then(data => {
       console.log('Risposta dal server:', data);
       writeShop(user.uid, shopName, apiKey, password, data.shopData.name)
       toast.success(
         <div>
         Connect store success: {data.shopData.name}
         <Link style={{marginLeft: '5px'}} to="/import-product" >
           Go to import product
         </Link>
       </div>
       );
   })
   .catch(error => {
       console.error('Errore durante la richiesta:', error);
       toast.error(error)
   });
}
};



  return (
    <div className='connect'>
      <ToastContainer />
    <header className='connect-header'>
     <Link to='/'><img src={Arrow} alt='...' />Go Back</Link> 
    <img src={LogoConnect} alt='...'/>
    </header>
    <main>
      <section>
        <div className='container-connect'>
          <h3>Link a Store to your Account</h3>
          <p>Step 1. Open your Shopify Admin and create a custom app</p>
          <ul>
            <li>1.1. Head to your Shopify Admin and open your <b>App and sales channel settings</b> section.</li>
            <li> 1.2. In the upper right, click <b>Develop apps</b>.</li>
            <li>1.3. In the upper right again, click <b>Create an app</b>.</li>
            <li>1.3.1 If you don't see the <b>Create an app</b> button in the upper right, click on <b>Allow custom app development</b> first.</li>
            <li>1.4. Enter the name of the app such as <span>Synchronizer Master <i className="fas fa-clipboard"></i></span> and click <b>Create app</b>.</li>
          </ul>
          <div className='connect-card card-one'>
          </div>
          <p>Step 2. Configure the Admin API Scopes</p>
          <ul>
            <li>2.1. Inside the <span>Synchronizer Master <i className="fas fa-clipboard"></i></span>  app you've just created, click on <b>Configure Admin API scopes</b>.</li>
            <li> 2.2. Check the following items:
  <br />
  <br />
  <code><i className="fas fa-check"></i> read_products</code>
  <br />
  <code><i className="fas fa-check"></i> write_products</code>
  <br />
  <code><i className="fas fa-check"></i> read_themes</code>
  <br />
  <code><i className="fas fa-check"></i> write_themes</code>
</li>
            <li>
  <div className='yellow'>
    Please double-check that the scopes below are not checked instead of the ones above.
  </div>
  <br/>
  <code class='red'><i className="far fa-circle-xmark"></i> read_product_listings</code>
  <br/>
  <code class='red'><i className="far fa-circle-xmark"></i> write_product_listings</code>
</li>

            <li>2.3. In the upper right again, click Save.</li>
          </ul>
          <div className='connect-card card-two'></div>
          <p>Step 3. Obtain the Access Token and finish linking your store</p>
          <ul>
            <li>3.1. Inside the <span>Synchronizer Master <i className="fas fa-clipboard"></i></span> app you've created, click on <b>API credentials</b>.</li>
            <li>3.2. Click on <b>Install app</b>, then click on <b>Install</b> again.</li>
            <li>
            3.3. Click on <b>Reveal token once</b> and copy your <b>Access Token</b>.
            <br />
            <div className='red-card'>
            <b>WARNING!</b> You will only be able to copy your Access
            Token once. We recommend that you write it down
            somewhere just in case. If forgotten, you will need to
            repeat all the steps above to obtain a new Access Token
            </div>
            </li>
            <li>3.4. Paste your <b>Access Token</b> below.</li>
          </ul>
          <div className='connect-card card-three'></div>
          <p>Step 4. Obtain your store's myshopify.com domain</p>
          <ul>
          <li>4.1. Inside your Shopify Admin click on <b>Settings</b>.</li>
          <li>4.3. Head to the <b>Domains</b> section.</li>
          <li>4.3. Copy your <b>myshopify.com domain</b>.</li>
          <li>4.4. Paste your <b>myshopify.com domain</b> below.</li>
          <li>4.5. Click <b>Link Store</b> and voilà, you have now successfully linked your store to your account 🥳.'</li>
          </ul>
          <div className='connect-card card-four'></div>
          <form className='connect-form' onSubmit={handleSubmit}>
            <div className='label-container'>
            <i className="fas fa-earth-africa"></i>
            <label>Store (myshopify.com domain)</label>
            <input type='text' name='shopName' placeholder='my-store.myshopify.com' required/>
            </div>
            <div className='label-container'>
            <i className="fas fa-key"></i>
            <label>API Key</label>
            <input type='text' name='apiKey' placeholder='Api Key' required/>
            </div>
            <div className='label-container'>
            <label>Access Token</label>
            <i className="fas fa-key"></i>
            <input type='text' name='password' placeholder='shpat_abc123def456ghi789' required/>
            </div>
            <div className='label-container'>
            <button type='submit' className='submit'><i className="fas fa-link"></i> Link Store</button>
            </div>
          </form>
        </div>
      </section>
    </main>
    </div>
  )
};

export default ConnectStore;
