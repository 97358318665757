import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import './Refund.css';
const Refund = ({user}) => {
  return (
    <>
    <Navbar user={user} />
    <main className='refund'>
        <div className='container-refund'>
            <h1>Refund Policy for Subscriptions</h1>
            <p>Welcome to 3seconds.ai!</p>
            <p>Our subscription services aim to provide our customers with continuous value and
satisfaction. Understanding the nature of recurring services, we have outlined our policy
regarding subscription refunds and cancellations below.
</p>
<h3>1. Subscription Refunds</h3>
<p>We do not offer refunds for subscription services once they are activated. Given the
immediate access to our digital content, resources, and services upon subscription
commencement, all payments made to 3seconds.ai for subscription services are final
and non-refundable.
</p>
<h3>2. Cancellation of Subscription</h3>
<p>Subscribers may cancel their subscriptions at any time. To ensure a smooth and
hassle-free cancellation process, please follow these steps:</p>
<ul>
    <li>To cancel your subscription, please contact us at info@3seconds.ai with your
account details and request for cancellation.</li>
<li>Upon receiving your cancellation request, we will process the termination of your
subscription.</li>
<li>Once your subscription is canceled, you will not be charged for the following
subscription period. Please note that no refunds will be issued for the current
subscription period, but you will continue to have access to your subscription
until the end of your current billing cycle.
</li>
</ul>
<h3>3. Contact Information</h3>
<p>For any inquiries or concerns regarding our subscription services, refund, or cancellation
policy, please do not hesitate to contact us at info@3seconds.ai. Our team is dedicated
to ensuring your satisfaction and addressing any questions you may have.
</p>
<h3>4. Policy Changes</h3>
<p>3seconds.ai reserves the right to modify this policy at any time to better serve our
customers or comply with changing legal and regulatory environments. We encourage
our customers to review this policy periodically for any updates. Continued use of
3seconds.ai’s subscription services following
the posting of changes to these terms will indicate your acceptance of those changes.</p>
<p>Thank you for choosing 3seconds.ai for your needs. We are committed to providing you
with quality services and support.</p>
</div>
</main>
    <Footer />
    </>
  )
};

export default Refund;
