import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, update, onValue, push,serverTimestamp } from 'firebase/database';
import { getAuth,signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword,sendEmailVerification, sendPasswordResetEmail} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA6Q5Ke-4V-175UpXxo9PXJizNZCA3ZPKo",
  authDomain: "seconds-3.firebaseapp.com",
  databaseURL: "https://seconds-3-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "seconds-3",
  storageBucket: "seconds-3.appspot.com",
  messagingSenderId: "1093968035999",
  appId: "1:1093968035999:web:7de69f57359a126abda2aa",
  measurementId: "G-L90EG965G5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth();

function writeUserData(userId, name, email) {
    const db = database;
    const userRef = ref(db, 'users/' + userId);
    var data = new Date();
    var gg, mm, aaaa;
    gg = data.getDate() + "/";
    mm = data.getMonth() + 1 + "/";
    aaaa = data.getFullYear();
    const dataComplete = gg + mm  + aaaa;
    var Hh, Mm;
    Hh = data.getHours() + ":";
    Mm = data.getMinutes();
    const hoursComplete = Hh +  Mm;
         set(userRef, {
          username: name,
          email: email,
          plan: 'free',
          lastSeen: dataComplete + '/' + hoursComplete,
        });
};

function readDate(userId) {
    const db = database;
    const userRef = ref(db, 'users/' + userId);
  
    return new Promise((resolve, reject) => {
      onValue(userRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data);
        } else {
          console.log('User not fund');
          reject(new Error('User  not fund'));
        }
      });
    });
};

function checkUsername(username) {
    const db = getDatabase();
    const usersRef = ref(db, 'users');
  
    return new Promise((resolve, reject) => {
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const users = snapshot.val();
          const usernames = Object.keys(users).map((userId) => users[userId].username);
  
          if (usernames.includes(username)) {
            resolve('Username already exists')
          } else {
            resolve('Username available');
          };
        } else {
          resolve('Not users in the database');
        };
      });
    });
  };


function updateLoginDate(userId) {
    return new Promise((resolve, reject) => {
        const db = database;
        const userRef = ref(db, 'users/' + userId);
        var data = new Date();
        var gg, mm, aaaa;
        gg = data.getDate() + "/";
        mm = data.getMonth() + 1 + "/";
        aaaa = data.getFullYear();
        const dataComplete = gg + mm + aaaa;
        var Hh, Mm;
        Hh = data.getHours() + ":";
        Mm = data.getMinutes();
        const hoursComplete = Hh + Mm;

        update(userRef, {
            lastSeen: dataComplete + '/' + hoursComplete,
        }).then(() => {
            resolve(); 
        }).catch((error) => {
            reject(error); 
        });
    });
};


function updatePlan(userId, plan_name, email) {
  return new Promise((resolve, reject) => {
      const db = database;
      const userRef = ref(db, 'users/' + userId);
      update(userRef, {
          plan: plan_name,
          emailBuyer: email
      }).then(() => {
          resolve(); 
      }).catch((error) => {
          reject(error); 
      });
  });
};


async function writeShop(userId, shopName, apiKey, password, name) {
  const db = database;
  const userRef = ref(db, 'users/' + userId + '/shop');

  // Eseguire una query per verificare se esiste già uno shop con lo stesso nome
  const snapshot = await get(userRef);
  const existingShops = [];

  if (snapshot.exists()) {
    // Se ci sono già shop nel percorso, aggiungili all'array existingShops
    snapshot.forEach((childSnapshot) => {
      const existingShop = childSnapshot.val();
      existingShops.push(existingShop.shopName);
    });
  }

  // Verifica se shopName è già presente tra gli existingShops
  if (!existingShops.includes(shopName)) {
    // Utilizza push per aggiungere un nuovo sotto-nodo solo se non esiste già uno shop con lo stesso nome
    const newShopRef = push(userRef);

    set(newShopRef, {
      shopName: shopName,
      apiKey: apiKey,
      password: password,
      name: name
    });
  } else {
    console.log("Lo shop con questo nome esiste già.");
  }
};

async function readShop(userId) {
  const db = database;
  const userShopRef = ref(db, 'users/' + userId + '/shop');

  try {
    const snapshot = await get(userShopRef);

    if (snapshot.exists()) {
      const shops = [];

      snapshot.forEach((childSnapshot) => {
        const shopData = childSnapshot.val();
        shops.push(shopData);
      });

      return shops;
    } else {
      console.log('Nessun negozio trovato per l\'utente con ID:', userId);
      return [];
    }
  } catch (error) {
    console.error('Errore durante la lettura degli shop:', error);
    return [];
  }
};



async function writeVariable(userId, userPlan) {
  try {
    const db = database; // Ottieni un'istanza del database
    const userRef = ref(db, 'users/' + userId + '/create');

    // Ottieni la data corrente del server
    const currentTimestamp = serverTimestamp();

    // Leggi il valore corrente di 'count' e 'date' dal database
    const snapshot = await get(userRef);
    const currentCount = snapshot.val() ? snapshot.val().count : 0;
    const createDate = snapshot.val() ? snapshot.val().date : null;

    // Inizializza il contatore a 1 se è la prima volta che si crea la variabile
    const initialCount = createDate ? currentCount : 0;

    if (createDate) {
      // Calcola la differenza di tempo in millisecondi
      const timeDifference = currentTimestamp - createDate;

      // Calcola il numero di millisecondi in 31 giorni
      const thirtyOneDaysInMillis = 31 * 24 * 60 * 60 * 1000;

      if (timeDifference >= thirtyOneDaysInMillis) {
        // Se sono passati 31 giorni, reimposta il contatore a 0 
        console.log('Resetting counter to 0 after 31 days for Starter plan.');
        await set(userRef, { count: 0, date: currentTimestamp });
      }
    }

    // Verifica il piano dell'utente e il massimo consentito di count
    const maxCount = getMaxCountByPlan(userPlan);

    if (initialCount >= maxCount) {
      return { success: false, message: `${userPlan} plan allows only a maximum of ${maxCount} Product Pages Month.` };
    } else {
      // Incrementa il contatore solo se il piano lo consente
      const newCount = initialCount + 1;

      // Crea un oggetto con la data e il nuovo contatore
      const dataToUpdate = {
        date: currentTimestamp,
        count: newCount
      };

      // Scrivi i dati nel database
      await set(userRef, dataToUpdate);

      console.log('Variable successfully written');
      return { success: true, message: 'Variable successfully written' };
    }
  } catch (error) {
    console.error('Error writing variable:', error.message);
    return { success: false, message: 'Error writing variable: ' + error.message };
  }
};

// Funzione ausiliaria per ottenere il massimo consentito di count in base al piano
function getMaxCountByPlan(userPlan) {
  switch (userPlan) {
    case '3seconds Starter':
      return 50;
    case '3seconds Pro':
      return 150;
    case '3seconds God':
      return 250;
    default:
      return 0;
  }
};

async function writeProduct(userId, title, image, url) {
  const db = database;
  const userRef = ref(db, 'users/' + userId + '/products');
  var data = new Date();
  var gg, mm, aaaa;
  gg = data.getDate() + "/";
  mm = data.getMonth() + 1 + "/";
  aaaa = data.getFullYear();
  var Hh, Mm, Ss;
  Hh = data.getHours() + ":";
  Mm = data.getMinutes() + ":";
  Ss = data.getSeconds();
  const newProductRef = push(userRef);
  const product = {
    title: title,
    image: image,
    url: url,
    date: gg + mm + aaaa + ' ' + Hh + Mm + Ss,
  };

  await set(newProductRef, product);
};



export {auth, createUserWithEmailAndPassword,  signInWithEmailAndPassword,  writeUserData, readDate, updateLoginDate, sendEmailVerification, sendPasswordResetEmail, checkUsername, signOut, updatePlan, writeShop, readShop, writeVariable, writeProduct };
