import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {auth, signOut} from '../Firebase/Firebase';
const DropDownUser = ({user, handleOpenBanner}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const logOut = () => {
    signOut(auth).then(() => {
        window.location.href = '/';
      }).catch((error) => {
      });
  };
 
  return (
    <div className="custom-dropdown-import">
    <div className="dropdown-header-import" style={{textTransform: 'uppercase' }}  onClick={handleToggle}>
    <i className="far fa-user"></i>
      {user}
      <i className="fas fa-angle-down"></i>
    </div>
    {isOpen && (
      <ul className="dropdown-options-import userdrop">
            <li><Link to='/profile'><i className="far fa-circle-user"></i>My Account</Link></li>
            <li><Link to='/product-history'><i className="fas fa-history"></i>Products History</Link></li>
            <li onClick={logOut}><i className="fas fa-right-to-bracket"></i>Logout</li>
      </ul>
)}
  </div>
  )
};

export default DropDownUser;
