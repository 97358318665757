import React from 'react';
import { Link } from 'react-router-dom';
import './PlanBanner.css';

const PlanBanner = ({open, handleClose}) => {
    const handleClickOutside = (event) => {
        console.log(event.target.className)
        if (event.target.className === 'container-form active') {
          handleClose();
        };
      };
  return (
    <div className={open === true ? 'container-form active': 'container-form'} onClick={handleClickOutside}>
    <div className='active-plan'>
      <h3>Your Plan is Activated</h3>
      <p>Enjoy</p>
      <Link to='/connect'>Connect to Shopify Store</Link>
    </div>
    </div>
  )
};

export default PlanBanner;
