import React from 'react';
import './Login.css';
import { signInWithEmailAndPassword, auth, updateLoginDate, readShop, readDate} from '../Firebase/Firebase';
import { toast } from 'react-toastify';
import LoginLogo from '../../image/loginLogo.png';
import 'react-toastify/dist/ReactToastify.css';
const Login = ({open, handleClose, handleOpen, handleOpenForgot}) => {
  const signIn = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    signInWithEmailAndPassword(auth, email, password)
  .then(async (userCredential) => {
    // Signed in 
    console.log('login:', userCredential)
   // window.location.href = '/connect';
    handleClose();
    const response = await readShop(userCredential.user.uid);
    const plan = await readDate(userCredential.user.uid);
    console.log('il plan:', plan.plan)
    console.log('database:');
    if(response && response.length > 0) {
      const date = {
        shopName: response[0].shopName,
        apiKey: response[0].apiKey,
        password: response[0].password
     };
     fetch('https://threeseconds.onrender.com/connect-shopify', {
       method: 'POST',
       headers: {
           'Content-Type': 'application/json',
       },
      credentials: 'include',
       body: JSON.stringify(date),
     })
       .then(response => response.json())
       .then(data => {
           console.log('Risposta dal server:', data);
           window.location.href = '/import-product';
       })
       .catch(error => {
           console.error('Errore durante la richiesta:', error);
           toast.error(error)
       });
    } else if (plan.plan === 'free'){
      window.location.href = '/';
    } else {
      const user = userCredential.user;
      updateLoginDate(user.uid).then(() => {
        window.location.href = '/connect';
    }).catch((error) => {
        toast.error(error);
    });
    };
  })
  .catch((error) => {
    const errorCode = error.code;
    toast.error(errorCode)
  });
  };
  const handleClickOutside = (event) => {
    console.log(event.target.className)
    if (event.target.className === 'container-form active') {
      handleClose();
    };
  };

 

  return (
    <div className={open === true ? 'container-form active': 'container-form'} onClick={handleClickOutside}>
    <form className='login-form' onSubmit={signIn}>
     <img src={LoginLogo} alt='...'/>
      <h3>Game Changer for E-Commerce: The AI Tool Redefining Success</h3>
      <p>Sign Up Now. No Commitment.</p>
      <input type="email" name="email" placeholder="email@example.com" required></input>
      <input type="password" name="password" placeholder="Enter your password" required />
      <button  onClick={handleOpenForgot} className='forgot'>Forgot Password?</button>
      <button  className='submit' type="submit">Login to My Account</button>
     <p style={{fontSize: '13px'}}>Dont have an Account?<button onClick={handleOpen} className='sign-up'>Sign Up</button></p>
    </form>
    </div>
  )
};

export default Login;
