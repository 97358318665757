import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './Privacy.css';

const Privacy = ({user}) => {
  return (
    <>
      <Navbar user={user} />
      <main className='privacy'>
       <div className='container-privacy'>
        <h1>Privacy Policy</h1>
        <p>At 3Seconds, accessible from Seconds.ai, one of our top priorities is to protect the privacy of our visitors. This document contains the types of information that is collected and stored by 3Seconds and how we use it.
         If you have any additional questions or would like more information about our privacy policy, please do not hesitate to contact us.
         This privacy policy applies only to our online activities and is valid for visitors to our website with respect to information they have
         shared and/or collected in 3Seconds. This policy does not apply to information collected offline or through channels other than this website.</p>
         <h3>SECTION 1: CONSENT</h3>
         <p>By using our website, you hereby accept our privacy policy and agree to its terms.</p>
         <h3>SECTION 2: SITE HOSTING AND DATA STORAGE</h3>
         <p>
Our website is hosted on AWS. All your data is stored on the AWS (Amazon) data storage system. Your data is stored on a secure server
protected by a firewall.</p>
<h3>SECTION 3: INFORMATION WE COLLECT</h3>
        <p>
The personal information we request from you. The reasons why we ask for your personal information will be made clear to you when we
ask you to provide it. If you contact us directly, we may receive additional information about you, such as your name, e-mail address,
telephone number, the content of any message and/or attachments you may send us, and any other information you choose to provide.
When you register for an account, we may ask for your contact information, including items such as your name, company name, address,
e-mail address and telephone number.</p>
<h3>SECTION 4: HOW WE USE YOUR INFORMATION</h3>
<p>
We use the information we collect in a variety of ways, including to:
- Provide, operate and maintain our website.
- Improve, personalize and develop our website.
- Understand and analyze how you use our web site.
- Develop new services, features and functionality.
- Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and
other information relating to the web site, and for marketing and promotional purposes.</p>
<h3>SECTION 5: LOG FILES</h3>
<p>
3Seconds follows a standard procedure for the use of log files. These files record visitors when they visit web sites. All hosting companies do
this, as does part of the analysis of hosting services. Information collected by log files includes Internet Protocol (IP) addresses, browser
type, Internet Service Provider (ISP), date and time, referring/exit pages and possibly number of clicks. These data are not linked to any
personally identifiable information. The purpose of this information is to analyze trends, administer the site, track users' movements on
the site and gather demographic information.</p>
<h3>SECTION 6: COOKIES AND WEB BEACONS</h3>
<p>
Like any other website, 3Seconds uses "cookies". These cookies are used to store information such as visitors' preferences and which pages
of the website the visitor has accessed or visited. This information is used to optimize the user experience by personalizing the content of
our web pages based on visitors' browser type and/or other information.</p>
<h3>SECTION 7: PAYMENT</h3>
<p>
If you make your purchase through a direct payment platform, we will store your credit card information. This information is encrypted in
accordance with the data security standard established by the payment card industry (PCI-DSS standard). All direct payment platforms
are PCI-DSS compliant, managed by the PCI Security Standards Council, a joint effort of companies such as Visa, MasterCard, American
Express and Discover. PCI-DSS requirements enable secure processing of credit card data by our store and its service providers.</p>
<h3>SECTION 8: DISCLOSURE'</h3>
<p>We may disclose your personal information if we are required to do so by law or if you violate our terms and conditions and terms of use.</p>
<h3>SECTION 9: LINKS AND REDIRECTIONS</h3>
<p>
You should certainly leave our website by clicking on links on our site. We assume no responsibility for the privacy policies of these other
sites and recommend that you read their policies carefully.</p>
<h3>SECTION 10: ADVERTISING PARTNER PRIVACY POLICIES</h3>
<p>
You can consult this list to find the privacy policy of each of 3Seconds's advertising partners. Third-party ad servers or networks use
technologies such as cookies, JavaScript or Web beacons that are used in their respective advertisements and links that appear on 3Seconds,
which are sent directly to the user's browser. They automatically receive your IP address when this happens. These technologies are used
to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content you see on the websites you
visit. Please note that 3Seconds has no access to or control over these cookies used by third-party advertisers.</p>
<h3>SECTION 11: THIRD PARTY PRIVACY POLICIES</h3>
<p>
3Seconds's privacy policy does not apply to other advertisers or websites. We therefore advise you to consult the respective privacy policies
of these third-party ad servers for more detailed information. It may include their practices and instructions on how to opt-out of certain
options. You can find a complete list of these privacy policies and their links here : Links to privacy policies. You can choose to disable
cookies through your browser options. For more detailed information on cookie management with specific web browsers, you can visit
the respective websites of these browsers.</p>
<h3>SECTION 12: RGPD DATA PROTECTION RIGHTS</h3>
<p>
We want to make sure that you are fully aware of all your data protection rights. Every user has the following rights:
The right of access - You have the right to request copies of your personal data. You may be charged a small fee for this service.
The right of rectification - You have the right to request that we correct any information that you believe to be inaccurate. You also have
the right to ask us to complete any information you consider incomplete.
The right to erasure - You have the right to request that we erase your personal data, under certain conditions.
The right to restrict processing - You have the right to ask us to restrict the processing of your personal data, under certain conditions.
The right to object to processing - You have the right to object to the processing of your personal data, under certain conditions.
The right to data portability - You have the right to request that we transfer the data we have collected to another organization, or
directly to you, under certain conditions.
If you make such a request, we have one month to respond. If you wish to exercise any of these rights, please contact us.</p>
<h3>SECTION 13: INFORMATION ABOUT CHILDREN</h3>
<p>
Another part of our priority is to add protection for children when using the internet. We encourage parents and guardians to observe,
participate and/or monitor and guide their online activities. If you believe that your child has provided this type of information on our
website, we strongly encourage you to contact us immediately and we will make every effort to promptly remove such information from
our records.</p>
<h3>SECTION 14: CONTACT US</h3>
<p>
If you have any questions about this privacy policy, please contact us by e-mail at support@3Seconds.com. Please note that information you
submit via e-mail may not be completely secure. Please do not include sensitive information in your messages.</p>
<h3>SECTION 15: Shopify Application</h3>
<p>
We are committed to protecting your personal information and your right to privacy.
If you have any questions or concerns about our policy, or our personal information practices, please contact us at support@3Seconds.com.
</p>
<p>When you use our application, you are trusting us with your personal information. We take your privacy very seriously. In this privacy
policy, we aim to explain as clearly as possible what information we collect, how we use it and what rights you have in relation to it. We
hope you will take the time to read it carefully, as it is important.
If you do not agree with the terms of this privacy policy, please stop using our app and services.This privacy policy applies to all
information collected via our app, 3Seconds, and/or any associated services, sales, marketing or events (we refer to them collectively in this
privacy policy as the "Services").</p>
<h3>1.What Information Do We Collect?
Information Collected via Shopify APIs:</h3>
<p>We collect product information via Shopify APIs in order to push products to your Shopify
product catalog.
Merchant Information: We do not collect any additional information directly from merchants.
Merchant Customer Information: We do not collect any information directly from merchant customers.</p>
<h3>2.How Do We Use Your Information?</h3>
<p>Weuse the information we collect or receive to provide the following services:
To push products to your Shopify product catalog via Shopify APIs.
To facilitate the seamless integration and operation of 3Seconds with your Shopify store.</p>
<h3>3. How Long Do We Keep Your Information?</h3>
<p>We do not store your information. Product data is used in real time to push products to your Shopify product catalog and is not retained
or used for any other purpose.</p>
<h3>4.Where Do We Store or Process Your Information?</h3>
<p>As we do not store or maintain any data, this section is not applicable.</p>
<h3>5.How Can You Contact Us?</h3>
<p>If you have any questions or comments about this policy, you can email us at contact@3Seconds.ai.</p>
<h3>6.How Can You Review, Update or Delete the Data We Collect From You?</h3>
<p>Since we do not collect or store any of your data, this section is not applicable.
But if you want to uninstall the 3Seconds application from your shopify space you can uninstall it from your shopify store.</p>
<h3>7.How do we ensure the security of your information?</h3>
<p>Although we do not store your information, we prioritize the secure transmission of data to Shopify APIs by employing technical and
organizational security measures</p>
       </div>
      </main>
      <Footer />
    </>
  )
};

export default Privacy;
