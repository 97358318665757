import React from 'react';
import './Contact.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Img from '../../image/contact/SVG.png';
import image from '../../image/contact/Mask group.png';
import arrow from '../../image/contact/arrow.png';

const Contact = ({user}) => {
 
  return (
    <>
      <Navbar user={user} />
    <main className='contact'> 
    <section>
    <div className='container-contact'>
     <img src={Img} alt='...'/>
     <img className='effect-contact' src={image} alt='...' />
     <form className='contact-form' action="https://formsubmit.co/info@3seconds.ai" method="POST">
      <h1>Contact the team!</h1>
     <div className="input-field">
    <input id='username' type="text" name='name' required /> 
    <label htmlFor='username'>First and Last Name</label>
  </div>
  <div className="input-field">
    <input id='email' type="email" name='email' required /> 
    <label htmlFor='email'>Email</label>
  </div>
  <div className="input-field">
    <textarea name='message' id='message'></textarea>
    <label htmlFor='message'>Message</label>
  </div>
  <div className='input-field'>
  <button className='contact-submit' type='submit'>Contact us</button>
  </div>
     </form>
    </div>
    </section>
    <section>
      <div className='contact-social-container'>
      <h2><img src={arrow} alt='...' /> Find us on our social networks</h2>
      <div className='contact-socials'>
       <div className='contact-social'>
        <h3>Facebook</h3>
        <p>Sharing the latest trendy lists of winning products.</p>
       </div>
       <div className='contact-social'>
        <h3>Instagram</h3>
        <p>Regular competitions and sharing of winning products.</p>
       </div>
       <div className='contact-social'>
        <h3>Telegram</h3>
        <p>3Seconds's best kept secrets are here.</p>
       </div>
       <div className='contact-social'>
        <h3>TikTok</h3>
        <p>Find the best winning products on Tiktok.</p>
       </div>
      </div>
      </div>
    </section>
    </main>
    <Footer/>
    </>
  )
};

export default Contact;
