import React from 'react';
import './Terms.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';

const Terms = ({user}) => {
  return (
    <>
   <Navbar user={user} />
    <main className='terms'>
        <div className='container-terms'>
        <h1>General Terms and Conditions of Sale</h1>
<p>Welcome to 3seconds! This site is operated by XOXO Commerce, Inc., headquartered at
651 N Broad St Suite 206, Middletown, Delaware 19709 USA, and registered in the
Delaware Commercial Register. These general terms and conditions describe the rules
and regulations for the use of the 3seconds website, located at 3seconds.com. By
accessing this website, we assume you accept these terms and conditions. We reserve
the right, at our sole discretion, to revise these terms and conditions from time to time.
Please regularly check this page to ensure you are up to date. The following terminology
applies to these Terms and Conditions, Privacy Statement, and all Agreements: "Client,"
"You," and "Your" refer to you, the person logging on to this website and compliant with
the company’s terms and conditions. "The Company," "Ourselves," "We," "Our," and "Us,"
refers to our Company. "Party," "Parties," or "Us," refers to both the Client and ourselves.</p>
<h3>SERVICE AND WEBSITE DESCRIPTION</h3>
<p>XOXO Commerce, Inc. owns or licenses the 3seconds platform. 3seconds is a
Software as a Service (SaaS) providing access to millions of listings and
placements from social networks. It is a business intelligence solution that
enables spying on marketing strategies worldwide.</p>
<h3>2. COOKIES</h3>
<p>We use cookies. By accessing 3seconds, you agreed to use cookies in agreement
with 3seconds's Privacy Policy. Most interactive websites use cookies to let us
retrieve the user’s details for each visit. Cookies are used by our website to
enable the functionality of certain areas to make it easier for
people visiting our website. Some of our affiliate/advertising partners may also use
cookies.</p>
<h3>3. INTELLECTUAL PROPERTY</h3>
<p>Unless otherwise stated, 3seconds and/or its licensors own the intellectual
property rights for all material (data, texts, images, sounds, graphics, logos,
trademarks, symbols, video, visual, oral, or other digital materials and all other
contents and descriptions) on 3seconds. All intellectual property rights are
reserved. You may access this from 3seconds for your personal use, subject to
restrictions set in these terms and conditions. You must not:
<br />
● Republish material from 3seconds.
<br />
● Sell, rent, or sub-license material from 3seconds.
<br />
● Reproduce, duplicate, or copy material from 3seconds.
<br />
● Redistribute content from 3seconds.</p>
<br />
<h3>4. ACCOUNT REGISTRATION</h3>
<p>To access or use certain features of the services, you must register and create an
account. You agree to provide accurate, current, and complete information during
the registration process and at other times when you use the services, and to
update such information to keep it accurate, current, and complete. We reserve
the right, in our sole discretion, to refuse to keep accounts or provide services to
anyone. We reserve the right to suspend or terminate your account if any
information provided during the registration process or at other times proves to
be inaccurate, not current, incomplete, or fraudulent.</p>
<h3>5. PERSONAL INFORMATION</h3>
<p>As part of the registration process, you will provide your email address,
password, and nationality. You agree to provide accurate and current information
regarding your nationality. Your email address and password are personal to you,
and under no circumstances can you allow one or more other people to use your
username or password. We are not responsible for any harm related or
connected to the theft or misappropriation of your email or password, the
disclosure of your email or password, or your authorization of anyone else to use
your email or password. You agree to immediately notify us if you become aware
of or believe there is or may have been any unauthorized use of your email or
password. Finally, you are informed that we may delete your account if it appears
to be false or suspicious without any justification on our part.</p>
<h3>6. LIMITATION OF LIABILITY</h3>
<p>You acknowledge that it would be unreasonable to hold us liable regarding the
information that the website contains. While we strive to ensure the accuracy of
the information on this site, we do not guarantee its completeness or accuracy;
nor do we commit to ensuring that the website remains available or that the
material on the website is kept up to date. To the maximum extent permitted by
applicable law, we exclude all representations, warranties, and conditions. Our
liability is limited and excluded to the maximum extent permitted under
applicable law. We will not be liable for any direct, indirect, or consequential loss
or damage arising under these terms and conditions or in connection with our
website, whether in tort, contract, or otherwise - including, without limitation, any
loss of profit, contracts, business, goodwill, data, income, revenue, or anticipated
savings. Furthermore, through 3seconds.com, you may be redirected to other
websites that are not under our control. We have no control over the nature,
content, and availability of those sites. The inclusion of any links does not
necessarily imply a recommendation or endorse the views expressed within
them.</p>
<h3>7. POTENTIAL ISSUES</h3>
<p>There may be times when our administrators improve the site's development, so
the database is no longer fed for 24 to 48 hours. Beyond these hours, all
collected data will be accessible on the site. Be aware that some links, some
videos may be broken. Also, note that some links redirect to an ad that is no
longer available or not visible to the public. That's why some links seem dead, but
they are not. Finally, you are aware that 3seconds may experience server outages
and not be fed for up to 3 to 4 weeks maximum.</p>
<h3>8. PRICING</h3>
<p>Prices are indicated in euros, including all taxes. We may change the prices at
any time, but you will be informed if these changes affect you. The
telecommunication costs needed to access 3seconds websites are your
responsibility.</p>
<h3>9. PAYMENT</h3>
<p>Payments are made immediately after the order. Secure online payments by
credit card are processed by our payment provider. The transmitted information
is encrypted and cannot be read during transport over the network. Once payment is initiated, the transaction is immediately debited after verifying the
information. According to the provisions of the Monetary and Financial Code, the
payment commitment made by card is irrevocable. By providing your banking
information at the time of sale, you authorize us to debit your card for the amount
related to the stated price. You confirm that you are the legal holder of the card to
be debited and that you are legally entitled to use it. In case of error, or if it is
impossible to debit the card, the sale is immediately and automatically cancelled.</p>
<h3>10. BILLING</h3>
<p>You agree to pay all fees and taxes incurred in connection with your use of our
services. After any payment, you can download an invoice in your user settings
(app.3seconds.com) It is your responsibility to provide us with your correct and
up-to-date personal information so that the invoice is accurate. If the information
we have collected from you is incorrect, this will result in an incorrect invoice.
You will be entirely and solely responsible. We may change the current fees and
taxes or add new ones from time to time, but we will inform you in advance.</p>
<h3>11. AVAILABLE PLANS</h3>
<p>Several plans are available. If you register on the platform without having chosen
a plan beforehand, you will automatically be on the Lite plan. The Lite plan is free
and allows you to discover the platform and its features. Please note that usage
restrictions apply to this plan. These restrictions take the form of a limitation in
terms of "credits" (see the chapter below). We may change, tighten, or model
these restrictions at any time, but you will be informed if they affect you. At any
time, you can change plans by going to your user settings. Two paid plans are
available on the platform: The Starter plan and the Premium plan. The only
notable difference between the two plans is the number of "credits" included, as
well as access to the "Shops" section included only in the Premium plan. The
Starter and Premium plans are both available as a monthly or annual
subscription.</p>
<h3>12. CREDIT CONCEPT</h3>
<p>The three plans present in 3seconds contain a limited amount of credits. For the
Starter and Premium plans, these credits are distributed either monthly or
annually, depending on whether you have opted for the subscription payable on a
monthly or annual basis. When your number of credits is non-zero, you can use
the platform and its features included in your plan. When your credits are
exhausted, you can either wait for your credits to be renewed in your next period
or buy additional credits to continue using the tool. The concept of credit is
specific to 3seconds. Credits are consumed during the use of the tool. For
example, during a query issued, when scrolling through advertisements, or when
a detail page is viewed. We reserve the right to modify, decrease, or increase the
number of credits consumed during the use of the tool. The concept of credits
may evolve or disappear at our sole discretion and without prior information on
our part. In each paid plan, a number of credits is defined, which may evolve
positively or negatively over the months. You will be informed if this evolution concerns you.</p>
<h3>13. START OF SUBSCRIPTION</h3>
<p>If you choose to purchase one of our paid subscriptions, the duration of your
subscription will begin as soon as we have confirmed your payment of the fees
according to the payment instructions on the website. At that time, you will have
access to the platform with the updated features described on the website for
the concerned subscription.</p>
<h3>14. SUBSCRIPTION TERMINATION</h3>
<p>At any time, you can terminate your subscription to end all future payments. After
cancelling your subscription, you will be able to access 3seconds until the end of
your current period (monthly or annual). For example:
<br />
● If you have an annual subscription and cancel it 4 months after your initial
payment, you will have access to the tool and all features included in your plan
for the next 8 months.
<br/>
● If you have a monthly subscription and cancel it 20 days after your initial
payment, you will have access to the tool and all features included in your plan
for the next 10 days. If you cancel your subscription before the end date of your
period and no longer wish to use the tool, we will not refund the remaining days
of your billing period or your remaining "credits". These remaining "credits" will be
lost.
</p>
<h3>15. AUTOMATIC RENEWAL</h3>
<p>Depending on the type of subscription you have subscribed to, payable either
monthly or annually, your subscription is renewed every 30 days or every year (365 days). To avoid being charged again on the renewal date, be sure to cancel
your subscription at least 24 hours in advance. You agree that if you no longer
need the service, it is your responsibility to cancel your subscription. We cannot
be held responsible if you forget to cancel your subscription.
</p>
<h3>16. REFUND POLICY</h3>
<p>You are fully informed that Delaware law does not provide, for electronic
commerce, any withdrawal period. That is, all paid services offered by 3seconds
are non-retractable and irrevocable, so we do not issue any refunds once the
order is completed. Therefore, we encourage you to read the terms and
conditions carefully before making a purchase:
<br />
● As soon as you register on 3seconds, you automatically have the opportunity to
test and judge the tool's features via a free plan. This way, you know what to
expect before making a purchase. For this reason, we do not offer refunds.
By making a purchase on our website, this means that you have read the terms and
conditions and fully accept them. In rare cases, we accept refund requests for new
users within 24 hours following their first purchase*.
<br />
● *Please note that:
<br />
● First purchase means the initial payment with an identified account and does not
include monthly renewal or plan upgrade.
<br />
● You will need to provide us with detailed and justified reasons for the refund.
<br />
● We do not refund in cases of non-use, dissatisfaction, or forgotten cancellations.</p>
<h3>18. SERVICE UPDATES</h3>
<p>From time to time, additional products and services may be made available to
you for an additional fee. The details of these offers will be published on our
website. We reserve the right to modify, terminate, or otherwise change the
services we offer at any time, at our sole discretion.</p>
<h3>19. HYPERLINKS TO OUR CONTENT</h3>
<p>The following organizations may create links to our website without prior written
approval:
<br />
● Government agencies.
<br />
● Search engines.
<br />
● News organizations.
<br />
● Online directory distributors may link to our website in the same manner as they
hyperlink to the websites of other listed businesses.
<br />
Systemwide Accredited Businesses except soliciting non-profit organizations, charity
shopping malls, and charity fundraising groups which may not hyperlink to our website.
These organizations may link to our home page, to publications, or to other Website
information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply
sponsorship, endorsement or approval of the linking party and its products and/or
services; and (c) fits within the context of the linking party’s site.
<br />
● We may consider and approve other link requests from the following types of
organizations:
<br />
● commonly-known consumer and/or business information sources;
<br />
● dot.com community sites;
<br />
● associations or other groups representing charities;
<br />
● online directory distributors;
<br />
● internet portals;
<br />
● accounting, law, and consulting firms; and
educational institutions and trade associations.
<br />
We will approve link requests from these organizations if we decide that: (a) the link
would not make us look unfavorably to ourselves or to our accredited businesses; (b)
the organization does not have any negative records with us; (c) the benefit to us from
the visibility of the hyperlink compensates the absence of 3seconds; and (d) the link is
in the context of general resource information.
These organizations may link to our home page so long as the link: (a) is not in any way
deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the
linking party and its products or services; and (c) fits within the context of the linking
party’s site.
<br />
● If you are one of the organizations listed in paragraph 2 above and are interested
in linking to our website, you must inform us by sending an e-mail to 3seconds.
Please include your name, your organization name, contact information as well
as the URL of your site, a list of any URLs from which you intend to link to our
Website, and a list of the URLs on our site to which you would like to link. Wait 2-3
weeks for a response.
Approved organizations may hyperlink to our Website as follows:
<br />
● By use of our corporate name; or
<br />
● By use of the uniform resource locator being linked to; or
By use of any other description of our Website being linked to that makes sense within
the context and format of content on the linking party’s site.
<br />
● No use of 3seconds's logo or other artwork will be allowed for linking absent a
trademark license agreement.</p>
<h3>20. iFrames</h3>
<p>Without prior approval and written permission, you may not create frames around
our Webpages that alter in any way the visual presentation or appearance of our
Website.</p>
<h3>21. Content Liability</h3>
<p>We shall not be held responsible for any content that appears on your Website.
You agree to protect and defend us against all claims that is rising on your
Website. No link(s) should appear on any Website that may be interpreted as
libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates
the infringement or other violation of, any third party rights.</p>
<h3>22. Your Privacy</h3>
<p>Please read privacy policy.</p>
<h3>23. Reservation of Rights</h3>
<p>We reserve the right to request that you remove all links or any particular link to
our Website. You approve to immediately remove all links to our Website upon
request. We also reserve the right to amen these terms and conditions and it’s
linking policy at any time. By continuously linking to our Website, you agree to be
bound to and follow these linking terms and conditions.</p>
<h3>24. Removal of links from our website</h3>
<p>If you find any link on our Website that is offensive for any reason, you are free to
contact and inform us any moment. We will consider requests to remove links
but we are not obligated to or so or to respond to you directly.</p>
<h3>25. Disclaimer</h3>
<p>To the maximum extent permitted by applicable law, we exclude all
representations, warranties and conditions relating to our website and the use of
this website. Nothing in this disclaimer will:
● limit or exclude our or your liability for death or personal injury;
● limit or exclude our or your liability for fraud or fraudulent misrepresentation;
● limit any of our or your liabilities in any way that is not permitted under applicable
law; or
exclude any of our or your liabilities that may not be excluded under applicable law.
The limitations and prohibitions of liability set in this Section and elsewhere in this
disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities
arising under the disclaimer, including liabilities arising in contract, in tort and for breach
of statutory duty.
● As long as the website and the information and services on the website are
provided free of charge, we will not be liable for any loss or damage of any
nature.</p>
<h3>26. FORCE MAJEURE</h3>
<p>The performance of our obligations is suspended in the event of the occurrence
of a fortuitous event or force majeure that would prevent the execution of these
obligations. We will inform you of the occurrence of such an event as soon as
possible.</p>
<h3>27. LAW AND APPLICABLE CLAUSES</h3>
<p>All clauses of these general terms and conditions of sale, as well as all purchase
and sale operations referred to therein, are subject to Delaware law. The invalidity
of a contractual clause does not entail the nullity of these general terms and
conditions.</p>
<h3>28. COMPLAINTS AND MEDIATION</h3>
<p>If necessary, you can submit any complaint by contacting us through our email:
info@3seconds.ai. For dispute resolution, the Delaware Civil Procedure Code
provides that you can resort to a consumer mediator. In case of failure of the
steps with our customer service, or in the absence of a response within two
months, you may submit the dispute to an external mediator who will endeavor, in
complete independence, to bring the parties together with a view to reaching an
amicable solution.</p>
<h3>29. DISPUTES</h3>
<p>As part of the consideration required by the website for viewing, using, or
interacting with this website, you agree to use mandatory arbitration for any
claim, dispute, or controversy of any kind (whether in contract, tort, or otherwise)
arising out of or relating to this purchase, this product, including solicitation
issues, privacy issues, and terms of use issues. Arbitration shall be conducted in
accordance with the rules of the arbitration association we have chosen in force
at the time the dispute arises. Under no circumstances shall you have the right to
go to court or have a jury trial. You will not have the right to engage in pre-trial
discovery except as provided in the rules; you will not have the right to participate
as a representative or member of any class of claimants pertaining to any claim
subject to arbitration; the arbitrator’s decision will be final and binding with
limited rights of appeal. The prevailing party shall be reimbursed by the other
party for any and all costs associated with the arbitration, including attorney fees,
collection fees, investigation fees, travel expenses.</p>
<h3>30. JURISDICTION AND VENUE</h3>
<p>If any matter concerning a purchase must be brought before a court, before or
after arbitration, you agree that the only and exclusive jurisdiction to be the state
and city declared in the contact information of the website owner unless
otherwise here specified. If the dispute is eligible for federal court, the proper
court shall be the closest federal court to the seller’s address.</p>
<h3>31. ENTIRE AGREEMENT</h3>
<p>These terms and conditions, together with our privacy policy, constitute the entire
agreement between you and us in relation to your use of our website, and
supersede all previous agreements in respect of your use of this website.
</p>
<h3>CUSTOMER SERVICE</h3>
<p>For any request for information, clarification, or any complaint, you must first
contact us to allow us to find a solution to the request. Our customer service is
accessible at the following email address: info@3seconds.ai</p>
        </div>
    </main>
    <Footer />
    </>
  )
};

export default Terms;
