import React, {useState, useEffect} from 'react';
import './dropDown.css';
import { Link } from 'react-router-dom';
import shopifyLogo from '../../image/shopify-logo.png';

const DropDown = ({ options, onChange, value }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    const handleOptionClick = (optionValue) => {
      onChange(optionValue);
      console.log('hai scelto:', optionValue)
      setIsOpen(false);
    };
    return (
        <div className="custom-dropdown-import">
        <div className="dropdown-header-import" onClick={handleToggle}>
        <img  src={shopifyLogo} style={{width: '15px', height: '15px', marginRight: '5px', marginLeft: '0'}} />
         {value ? value.name : value}
          <br />
          {value ? value.domain || value.shopName : value}
          <i className="fas fa-angle-down"></i>
        </div>
        {isOpen && (
          <ul className="dropdown-options-import">
            {options.map((option) => (
              <li key={option.value}
                onClick={() => handleOptionClick(option)}
              >
                 <i className="fab fa-shopify"></i>
                {option.name}
              </li>
            ))}
            <Link className='add-shop' to='/connect'><i className="fas fa-plus"></i> Add Shop</Link>
          </ul>
        )}
      </div>
    );
  };

export default DropDown;
