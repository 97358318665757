import React, {useEffect, useState} from 'react';
import './importProduct.css';
import LogoImport from '../../image/logoConnect.png';
import {readShop, readDate, writeVariable, writeProduct} from '../Firebase/Firebase';
import DropDown from '../DropDown/DropDown';
import DropDownUser from '../DropDownUser/DropDownUser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';
import Arrow from '../../image/arrow.png';


const ImportProduct = ({user, plan}) => {
const [shopData, setShopData] = useState([]);
const [shops, setShops] = useState([]);
const [selectedShop, setSelectedShop] = useState(null)
const [userName, setUserName] = useState(null);
const [selectedLanguage, setSelectedLanguage] = useState('English');
const [active, setActive] = useState(1);
const [openLoader, setOpenLoader] = useState(false);
const [angle, setAngle] = useState('Product Angle (Optional)');
const [place, setPlace] = useState('Write a specific angle for product copywritting');



const handleLanguageChange = (event) => {
  setSelectedLanguage(event.target.value);
};

const handleActive = (target) => {
setActive(target);
if(target === 1 ) {
  setAngle('Product Angle (Optional)');
  setPlace('Write a specific angle for product copywritting');
} else {
  setAngle('Keywords');
  setPlace('Write a list of keywords you want to use to build your product page');
};
};

const fetchShopData = async () => {
  try {
    const response = await fetch('https://threeseconds.onrender.com/shop-data', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
    },
     credentials: 'include', 
    });

    if (response.ok) {
      const data = await response.json();
      console.log('la sessione:', data);
      if (data.shopData && data.shopData.name) {
        setShopData(data.shopData);
        console.log('Il nome:', data.shopData.name);
        setSelectedShop(data.shopData);
      } else {
        toast.error('No store connected');
      }
    } else {
      console.error('Errore durante la richiesta:', response);
      toast.error('No store connected');
    }
  } catch (error) {
    console.error('Errore generale durante la richiesta:', error);
  };
};



const handleShopChange = (value) => {
  setSelectedShop(value);
  console.log('il valore e:', value);

  readDate(user.uid)
    .then((userData) => {
      const userShops = userData.shop;

      if (userShops && Object.keys(userShops).length > 0) {
        // Verifica che userShops esista e contenga almeno una proprietà
        Object.values(userShops).forEach(date => {
          console.log('dati:', date);
          if (date.name === value.name) {
            console.log('result:', date.name);
            const shopName = date.shopName;
            const apiKey = date.apiKey;
            const password = date.password;
            const dates = {
              shopName: shopName,
              apiKey: apiKey,
              password: password
            };

            fetch('https://threeseconds.onrender.com/connect-shopify', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
              body: JSON.stringify(dates),
            })
              .then(response => response.json())
              .then(data => {
                console.log('Risposta dal server:', data);
                setShopData(data.shopData.name);
                toast.success(
                  <div>
                    Connect store success: {data.shopData.name}
                  </div>
                );
              })
              .catch(error => {
                console.error('Errore durante la richiesta:', error);
                toast.error(error.message);
              });
          }
        });
      } else {
        console.error('Nessun negozio disponibile.');
      }
    })
    .catch((error) => {
      console.error('Errore nella lettura dei dati utente:', error);
    });
};


  useEffect(() => {
    if (user) {
      fetchShopData();
      readDate(user.uid)
        .then((userData) => {
          console.log('Dati utente:', userData);
          setUserName(userData.username)
        })
        .catch((error) => {
          console.error('Errore nella lettura dei dati utente:', error);
        });
        readShop(user.uid).then((result) => {
          setShops(result);
        });
    };
  }, [user]);
  

const sendProductUrl = async (event) => {
event.preventDefault();
if(active === 1 ){
  console.log('shoper:', selectedShop)
  const url = event.target.url.value;
  const angle = event.target.keywords.value;
  const language = selectedLanguage;
  console.log(url, angle,language);
  const date = {
    url: url,
    angle: angle,
    language: language
  };
    setOpenLoader(true);
    document.querySelector('main').style.filter = 'blur(10px)';
    document.querySelector('main').style.overflow = 'hidden';
    if(shopData) {
      fetch('https://threeseconds.onrender.com/import-product-angle', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(date),
      })
        .then(response => response.json())
        .then(async res => {
          if(res.status === 'success') {
            const count = await writeVariable(user.uid, plan);
            console.log(count)
            console.log(res.data.product)
            setOpenLoader(false);
            document.querySelector('main').style.filter = '';
            document.querySelector('main').style.overflow = '';
            toast.success(res.data.message);
            const url ='https://' + selectedShop.myshopify_domain + '/products' + '/' + res.data.product.handle
            writeProduct(user.uid,res.data.product.title,res.data.product.image,url);
          } else {
            setOpenLoader(false);
            document.querySelector('main').style.filter = '';
            document.querySelector('main').style.overflow = '';
            toast.error(res.message);
          };
        });
      } else {
        window.location.href = '/connect';
      };
} else {
  console.log('shoper:', selectedShop)
  const url = event.target.url.value;
  const angle = event.target.keywords.value;
  const language = selectedLanguage;
  console.log(url, angle,language);
  const date = {
    url: url,
    seo: angle,
    language: language
  };
    setOpenLoader(true);
    document.querySelector('main').style.filter = 'blur(10px)';
    document.querySelector('main').style.overflow = 'hidden';
    if(shopData) {
      fetch('https://threeseconds.onrender.com/import-product-seo', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(date),
      })
        .then(response => response.json())
        .then(async res => {
          if(res.status === 'success') {
          const count = await writeVariable(user.uid, plan);
          console.log(count)
          setOpenLoader(false);
          document.querySelector('main').style.filter = '';
          document.querySelector('main').style.overflow = '';
          toast.success(res.data.message);
          const url ='https://' + selectedShop.myshopify_domain + '/products' + '/' + res.data.product.handle
          writeProduct(user.uid,res.data.product.title,res.data.product.image,url);
          } else {
            setOpenLoader(false);
            document.querySelector('main').style.filter = '';
            document.querySelector('main').style.overflow = '';
            toast.error(res.message);
          };
        });
      } else {
        window.location.href = '/connect';
      };
}
  event.target.reset();
};

  return (
    <div className='import'>
      <Loader active={openLoader}/>
      <ToastContainer />
    <header className='connect-import'>
    <DropDown
     options={shops}
     value={selectedShop}
     onChange={handleShopChange}
    />
      <img src={LogoImport} alt='...' />
    <DropDownUser user={userName}/>
    </header>
    <main>
    <section>
      <div className='container-import-product'>
        <form className='import-product' onSubmit={sendProductUrl}>
          <label htmlFor='url'>Copy & paste Product URL</label>
          <input type='text' id='url' name='url' placeholder='3seconds.ai/product'  required/> 
          <label htmlFor='select-input'>Which import style do you prefer: ‘Angle’ focused or ‘SEO-focused’? 
          <span data-tooltip='Pick a simple focus for your writing, like showing how the product is easy to use, perfect for parents, or great for saving time This way, your words will clearly match what your audience cares about' data-flow="right">
            <i className="fas fa-circle-info">
              </i>
              </span>
              </label>
          <div className='select-import'>
            <button onClick={() => handleActive(1)} type='button'  className={active === 1 ? 'active' : ''}>SPECIFIC ANGLE</button>
            <button onClick={() => handleActive(2)} type='button' className={active === 2 ? 'active': ''}>SEO</button>
          </div>
          <label htmlFor='keywords'>
            {angle}
          <span data-tooltip='Please select between SPECIFIC ANGLE, where we tailor the description to emphasize your chosen marketing angle, or SEO, where we craft the product listing based on your specified keywords.' data-flow="right">
            <i className="fas fa-circle-info"></i>
            </span>
        </label>
          <input  type='text' id='keywords' name='keywords' placeholder={place} required/>
          <label  htmlFor='Language'>Language</label>
          <select name='languageSelect' id="languageSelect" value={selectedLanguage} onChange={handleLanguageChange}>
                 <option value="English">English</option>
                 <option value="French">French</option>
          </select>
          <button className='import' type='submit'><i className="fas fa-download"></i>Import Product</button>
          <Link  className='go-bck' to='/connect'><img src={Arrow} alt='...' />Go Back</Link>
        </form>
      </div>
    </section>
    </main>
  </div>
  )
};

export default ImportProduct;
