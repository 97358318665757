import React from 'react'
import './Loader.css';

const Loader = ({active}) => {
  return (
    <div className={active === true ? 'loader-container active' : 'loader-container'}>
   <h1> I am generating and importing your product.</h1>
    <span className="loader"></span>
    </div>
  )
}

export default Loader
