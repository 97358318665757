import React from 'react';
import Logo from '../../image/logo.png';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {auth, signOut} from '../Firebase/Firebase';

const Navbar = ({
   handleOpen,
    user
  }) => {

  const location = useLocation();
  const currentPath = location.pathname;

const Links = () => {
  return (
    <>
    {user ? (
    <Link to='/' className='login'>
    <i className='far fa-user'></i>
     Log out
  </Link>
    ) : (
      <Link to='/' className='login'>
      <i className='far fa-user'></i>
     Sign In
    </Link>
    )}
    </>
  )
};

const logOut = () => {
  signOut(auth).then(() => {
      window.location.href = '/';
    }).catch((error) => {
    });
};
const Buttons = () => {
  return (
    <>
    {user ? (
      <button onClick={logOut} className='login'>
        <i className='far fa-user'></i>
        Log out
      </button>
  ) : (
      <button onClick={handleOpen} className='login'>
        <i className='far fa-user'></i>
      Sign In
      </button>
    )}
    </>
  )
};

  return (
    <header>
      <Link to='/'>
        <img src={Logo} alt='logo' className='logo'/>
      </Link>
      <div className='button-container'>
     {currentPath !== '/privacy-polices' ? (
      <Buttons />
     ): (
       <Links/>
     )
    }
      </div>
    </header>
  );
};

export default Navbar;