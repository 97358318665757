import React,{useState, useEffect} from 'react';
import './Profile.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Arrow from '../../image/arrow.png';
import logo from '../../image/logoConnect.png';
import { readDate, updatePlan } from '../Firebase/Firebase';
import BillingImg from '../../image/Billing/image 28.png';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';


const Profile = ({user, plan}) => {
const [shop, setShopData] = useState([]);
const [selectPlan, setSelectPlane] = useState(plan);
const [planList, setPlanList] = useState([]);
const [open, setOpen] = useState(false);
const [openPayment, setOpenPayment] = useState(false);

    const fetchShopData = async () => {
        try {
          const response = await fetch('https://threeseconds.onrender.com/shop-data', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
          },
           credentials: 'include', 
          });
      
          if (response.ok) {
            const data = await response.json();
            console.log('la sessione:', data);
            if (data.shopData && data.shopData.name) {
              setShopData(data.shopData);
              console.log('Il nome:', data.shopData.name);
            } else {
              toast.error('No store connected');
            }
          } else {
            console.error('Errore durante la richiesta:', response);
            toast.error('No store connected');
          }
        } catch (error) {
          console.error('Errore generale durante la richiesta:', error);
        };
      };

useEffect(() => {
fetchShopData();
  if (planList.length === 0) {
    fetch('https://threeseconds.onrender.com/products')
      .then(response => {
        if (!response.ok) {
          throw new Error('Errore durante il recupero dei prodotti');
        }
        return response.json();
      })
      .then(products => {
        console.log('Prodotti:', products);
        setPlanList(products.map(product => ({ id: product.id, plan: product.name , interval: product.interval })));
      })
      .catch(error => {
        console.error('Si è verificato un errore:', error);
      });
  }
}, [planList]); 
const link = `https://${shop.domain}`;



const openBanner = () => {
setOpen(true);
document.querySelector('main').style.filter = 'blur(5px)';
};

const handleClickOutside = (event) => {
  if (open === true && event.target.className !== 'profile-banner') {
    setOpen(false);
    document.querySelector('main').style.filter = '';
  } else if (openPayment === true && event.target.className !== 'card-payment') {
    setOpenPayment(false);
    document.querySelector('main').style.filter = '';
  }
};

const payment = () => {
  setOpenPayment(true);
  document.querySelector('main').style.filter = 'blur(5px)';
};


const ChangePlan = async (e) => {
  console.log('plan list:', e.target.id);
  const userId = user.uid;
  const response = await readDate(userId);
  
  if (response) {
    const emailBuyer = response.emailBuyer;
    console.log(emailBuyer);
    
    if (emailBuyer) {
      try {
        const data = {
          emailBuyer: emailBuyer,
          productId: e.target.id, 
        };

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data),
        };
        console.log('Dati:', data);
        const response = await fetch('https://threeseconds.onrender.com/change-plan', requestOptions);
        const responseData = await response.json();
        if (response.ok && response.status === 200) {
        console.log('Risposta dal server:', responseData);
        setOpen(false);
        document.querySelector('main').style.filter = '';
        toast.success(responseData.message)
        updatePlan(user.uid,responseData.plan, responseData.emailBuyer);
        } else {
          setOpen(false);
          document.querySelector('main').style.filter = '';
          toast.error(responseData.error)
        }
        // Gestisci la risposta dal server come desideri
      } catch (error) {
        console.error('Errore durante la richiesta di cambio piano:', error);
        toast.error(error)
        // Gestisci l'errore
      }
    }
  }
};


const stripe = useStripe();
const elements = useElements();
const cardSubmit = async (e) => {
  e.preventDefault();
  console.log('user:', user)
  const userId = user.uid;
  const response = await readDate(userId);
  if (response) {
    const emailBuyer = response.emailBuyer;
    console.log(emailBuyer);
    if (emailBuyer) {
      const { error, token } = await stripe.createToken(elements.getElement(CardElement));
      console.log(token)
      fetch('https://threeseconds.onrender.com/update-payment-method', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailBuyer, token: token.id}),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Errore durante la richiesta.');
        }
        return response.json();
      })
      .then(data => {
        console.log(data.message);
        setOpenPayment(false);
        document.querySelector('main').style.filter = '';
        toast.success(data.message);
      })
      .catch(error => {
        console.error('Si è verificato un errore:', error);
        toast.error(error)
      });
    };
  };
};

const deleteSub = async () => {
  const userId = user.uid;
  const response = await readDate(userId);
  if (response) {
    const emailBuyer = response.emailBuyer;
    console.log(emailBuyer);
    if (emailBuyer) {
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ emailBuyer: emailBuyer }),
        };
    
        const response = await fetch('https://threeseconds.onrender.com/cancel', requestOptions);
        const responseData = await response.json();
    
        if (response.ok && response.status === 200) {
          console.log(responseData.message); 
          toast.success(responseData.message);
          updatePlan(user.uid,'free', emailBuyer);
          window.location.href = '/';
        } else {
          console.error(responseData.error); 
          toast.error(responseData.error);
        }
      } catch (error) {
        console.error('Error:', error); 
      };
    };
  };
};



  return (
    <div>
        <div className={open === true ? 'profile-banner open' : 'profile-banner'}>
          <ul>
    {planList.map(plan => (
      <li id={plan.id} key={plan.id} onClick={ChangePlan}>
        <div>{plan.plan}</div>
        <div>{plan.interval}</div>
      </li>
    ))}
  </ul>
        </div>
        <div className={openPayment === true ? 'card-payment open' : 'card-payment'}>
        <div className='container-left-content'>
          <img src={BillingImg} className='Billing-img' alt='...' />
          </div>
        <form className='payment-form' onSubmit={cardSubmit}>
        <h4>Enter payment details</h4>
        <label htmlFor='information'>Card information</label>
        <CardElement />
        <p>To ensure maximum security, we assure you that the card details provided to Stripe will not be stored in our systems in any form.</p>
        <button type='submit'>Change Card</button>
        </form>
        </div>
        <main className='profile' onClick={handleClickOutside}>
        <Link to='/import-product'><img src={Arrow} alt='...' />Go Back</Link> 
        <img src={logo} className='logo-profile'  alt='...'/>
            <ToastContainer />
        <div className='container-profile'>
          <div className='card-profile'>
            <div className='profile-header'>
                <h1><i className="far fa-circle-user"></i> My Account</h1>
            </div>
            <div className='profile-content'>
                <h3>{plan} Basic Subscription</h3>
                <span>for <Link target='_blank' to={link}>{shop.domain}</Link></span>
                <p>Your subscription renews every <b>month</b></p>
                <button onClick={() => openBanner()}>Change to Monthly Billing <i className="fas fa-arrow-right-long"></i></button>
                <button onClick={() => payment()}>Update Payments Method <i className="fas fa-credit-card"></i></button>
                <button onClick={() => deleteSub()} className='red-profile'>Cancel Subscription <i className="fas fa-ban"></i></button>
            </div>
          </div>
        </div> 
        </main>
    </div>
  )
}

export default Profile
